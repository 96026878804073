import React from "react";
import Header from "../../../components/header";
import Layout from "../../../components/layout";
import WithProviders from "../../../components/WithProviders";

import { Message } from "../../../lib/getMessage";
import stepOne from "../../images/step-one.png";
import stepOne2 from "../../images/step-one@2x.png";
import stepTwo from "../../images/step-two.png";
import stepTwo2 from "../../images/step-two@2x.png";
import stepThree from "../../images/step-three.png";
import stepThree2 from "../../images/step-three@2x.png";
import Underline from "../../images/underline.png";
import "./style.css";
import HeroBanner from "../../../components/HeroBanner";

const Step = (props) => (
  <div className={`step ${props.name}`}>
    <div className="step-media">
      <img
        src={props.image.normal}
        srcSet={`${props.image.xx} 2x`}
        alt="media"
        loading="lazy"
      />
      <div className={`content ${props.isDirectionRTL ? "rtl" : ""}`}>
        {props.desc}
      </div>
    </div>
    <div className="step-details">
      <p>{props.details}</p>
    </div>
  </div>
);

const ScanAndGo = (props) => {
  const { language, updateLanguage, direction } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={props.location}
    >
      <div>
        <Header
          current="product"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="scan-go">
          <HeroBanner
            title={<Message dictKey="products.scanandgo.title" />}
            className={`scan-banner ${isDirectionRTL && "scan-banner-rtl"}`}
            isDirectionRTL={isDirectionRTL}
          />
          <section className="scan-steps">
            <div className="header">
              <h2>
                <Message dictKey="products.scanandgo.scansteps.heading" />
              </h2>
              <img
                src={Underline}
                alt=""
                className="underline"
                loading="lazy"
              />
            </div>
            <div className="steps">
              <Step
                name="step-one"
                desc={<Message dictKey="step1" />}
                details={
                  <Message dictKey="products.scanandgo.scansteps.step1" />
                }
                image={{
                  normal: stepOne,
                  xx: stepOne2,
                }}
                isDirectionRTL={isDirectionRTL}
              />
              <Step
                name="step-two"
                desc={<Message dictKey="step2" />}
                details={
                  <Message dictKey="products.scanandgo.scansteps.step2" />
                }
                image={{
                  normal: stepTwo,
                  xx: stepTwo2,
                }}
                isDirectionRTL={isDirectionRTL}
              />
              <Step
                name="step-three"
                desc={<Message dictKey="step3" />}
                details={
                  <Message dictKey="products.scanandgo.scansteps.step3" />
                }
                image={{
                  normal: stepThree,
                  xx: stepThree2,
                }}
                isDirectionRTL={isDirectionRTL}
              />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(ScanAndGo);
